.loader4 {
    width: 45px;
    height: 45px;
    display: inline-block;
    padding: 0px;
    border-radius: 100%;
    border: 5px solid;
    border-top-color: #4caf50;
    border-bottom-color: rgb(0 0 0 / 74%);
    border-left-color: #4caf50;
    border-right-color: rgb(0 0 0 / 82%);
    -webkit-animation: loader4 1s ease-in-out infinite;
    animation: loader4 1s ease-in-out infinite;
    border-radius: 50% !important;
  }
  
  @keyframes loader4 {
    from {
      transform: rotate(0deg);
    }
  
    to {
      transform: rotate(360deg);
    }
  }
  
  @-webkit-keyframes loader4 {
    from {
      -webkit-transform: rotate(0deg);
    }
  
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  
  #pre-loader .loader4 {
    text-align: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    z-index: 99;
    margin: 0 auto;
  }
  