.template-product .tabs-listing .react-tabs__tab-list {
	border-bottom: 1px solid #e8e9eb;
	width: 100%;
	margin-bottom: 0;
}
.template-product .tabs-listing .react-tabs__tab-list li {
	float: none;
	display: inline-block;
	cursor: pointer;
}

.react-tabs__tab--selected {
	text-decoration: none;
	border: 0;
	color: #000;
	border-bottom: 1px solid #000;
	opacity: 1;
}
.product-thumb {
	height: 400px;
}

/* Used By Login Modal */
.form-elegant .font-small {
	font-size: 0.8rem;
}

.form-elegant .z-depth-1a {
	-webkit-box-shadow: 0 2px 5px 0 rgba(55, 161, 255, 0.26), 0 4px 12px 0 rgba(121, 155, 254, 0.25);
	box-shadow: 0 2px 5px 0 rgba(55, 161, 255, 0.26), 0 4px 12px 0 rgba(121, 155, 254, 0.25);
}

.form-elegant .z-depth-1-half,
.form-elegant .btn:hover {
	-webkit-box-shadow: 0 5px 11px 0 rgba(85, 182, 255, 0.28), 0 4px 15px 0 rgba(36, 133, 255, 0.15);
	box-shadow: 0 5px 11px 0 rgba(85, 182, 255, 0.28), 0 4px 15px 0 rgba(36, 133, 255, 0.15);
}

.form-elegant .modal-header {
	border-bottom: none;
}

.modal-dialog .form-elegant .btn .fab {
	color: #2196f3 !important;
}

.form-elegant .modal-body,
.form-elegant .modal-footer {
	font-weight: 400;
}

a.site-header__cart2 {
    border: 2px solid rgb(0, 0, 0);
    display: block;
    border-radius: 50% !important;
    width: 54px;
    margin-right: 23px;
    margin-top: -10px;
    position: relative;
}

.site-header__cart2::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    top: 42%;
    left: 54px;
    position: absolute;
}
.site-header__cart .icon {
	font-size: 30px;
}
i.icon.anm.anm-search-l {
    font-size: 30px;
}

  .btnFacebook { 
    width: 165px;
    height:40px;  
    background: #3b5998;
    color:white;
    border:0px transparent;  
    text-align: center;
    display: inline-block;
    margin-right:2px;
    cursor: pointer;
    justify-content: center;
  }
  button.btnGoogle { 
    width: 165px;
    height:40px;
    background: #db3236;
    color:white;
    border:0px transparent !important;
    text-align: center;
    margin-left:2px;
    background-color: rgb(240 165 86) !important;
    box-shadow: none !important;
    justify-content: center;
    border-radius :0px !important;
  }
  .btnFacebook:hover {
    background: #3b5998;
    opacity: 0.6;
  }
  .btnGoogle:hover {
    background: #db3236;
    opacity: 0.6;
  }
  
  @media only screen and (max-width : 399px) {
    .btnFacebook,.btnGoogle{
      width: 100%
    }
  }
  i.fa.fa-facebook {
    margin-right: 5px;
  }

  .btnGoogle span{
    padding: 0px !important;
  }

  a.wishlist.add-to-wishlist {
    cursor: pointer;
  }


  .infinitpagin ul li a {
    display: block;
    padding: 6px 12px;
    /* background: #cccc; */
    font-weight: bold;
    text-transform: capitalize;
    cursor: pointer;
}
.infinitpagin ul{
  display: flex;
  list-style: none;
}
.infinitpagin{
  display: flex;
  justify-content: center;
}

.infinitpagin ul li.selected a {
  background: green;
  color: white !important;
  border-radius: 50% !important;
}
.css-187mznn-MuiSlider-root {
  border-radius: 12px;
  box-sizing: content-box;
  display: inline-block;
  position: relative;
  cursor: pointer;
  touch-action: none;
  color: #28a745 !important;
  -webkit-tap-highlight-color: transparent;
  height: 4px;
  width: 95% !important;
  padding: 13px 4px !important;
  margin-left:2px ;
}
.css-eg0mwd-MuiSlider-thumb{
  width: 12px !important;;
  height: 12px !important;;
}

.loader4 {
  width: 45px;
  height: 45px;
  display: inline-block;
  padding: 0px;
  border-radius: 100%;
  border: 5px solid;
  border-top-color: #4caf50;
  border-bottom-color: rgb(0 0 0 / 74%);
  border-left-color: #4caf50;
  border-right-color: rgb(0 0 0 / 82%);
  -webkit-animation: loader4 1s ease-in-out infinite;
  animation: loader4 1s ease-in-out infinite;
  border-radius: 50% !important;
}

@keyframes loader4 {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader4 {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

#pre-loader .loader4 {
  text-align: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  z-index: 99;
  margin: 0 auto;
}

.collection-hero__image {
  height: 280px;
  opacity: 1;
  transform: translateY(-86PX);
}

input.btn.btn-info.btn-block.btn-round {
  border-radius: 0px;
}

label.swatchLbl.color.medium.rectangle.active{
  border: 3px solid #53BB68;
}

input#Quantity{
  width: 75px;
}

.MuiSlider-colorPrimary.MuiSlider-sizeMedium {
  width: 78% !important;
  border-radius: 12px;
  box-sizing: content-box;
  display: inline-block;
  position: relative;
  cursor: pointer;
  touch-action: none;
  color: #28a745 !important;
  -webkit-tap-highlight-color: transparent;
  height: 4px;
  padding: 13px 4px !important;
  margin-left:2px ;
}