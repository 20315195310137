
.box-row {
  display: flex;
  justify-content: space-between;
  width: 24vw;
  margin: auto;
}

.box-button {
  display: flex;
  align-items: center;
}

.box-label {
  text-align: center;
}

.box {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.box-icon {
  width: $medium;
  height: $medium;
  padding: 8px;
  margin-bottom: 4px;
  border-radius: 50%;
  border: 2px solid black;
  padding: 12px;
}

@media (max-width: 480px) {
  .box {
    width: $small;
    height: $small;
  }

  .box-icon {
    width: $small;
    height: $small;
  }

  svg {
    width: $small;
    height: $small;
  }

}

.box-tag {
  flex-grow: 1;
  min-width: 20px;
  height: 2px;
  background-color: black;
}

.box:first-child .box-tag-left {
  background-color: white;
}

.box:last-child .box-tag-right {
  background-color: white;
}

i.fa.fa-truck , i.fa.fa-opencart , i.fa.fa-credit-card-alt , i.fa.fa-check {
    font-size: 25px;
    justify-content: center;
    margin-top: 9px;
    margin-left: 1px;
    margin-right: 2px;
}

.box-icon.active {
    background-color: #9aed9a;
  }

  .mt-1.mb-1.spec-1{
    min-height: 82px;
  }