.side-navigation-panel-select-option span {
    display: inline-block;
    padding: 0;
}
.side-navigation-panel-select-option svg {
    float: right;
}
.sidebar .sidebar_widget .widget-content ul {
    margin: 0;
    margin-left: 15px;
    list-style: none;
}
.sidebar .sidebar_widget .widget-content ul li{
    padding: 0;
}
.side-navigation-panel-select-option-text{
    display: none !important;
}
.side-navigation-panel-select-inner-wrap{
    padding-top: 2px;
    padding-bottom: 2px;
}
.navigation ul{
    padding-top: 10px;
    padding-bottom: 10px;
}

ul.side-navigation-panel-select{
    padding-bottom: 10px;
}

.side-navigation-panel-select-option-wrap , .side-navigation-panel-select-inner-option-wrap{
    font-size: 16px;
    font-weight: 550;
    cursor: pointer;
}